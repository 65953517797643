import store from '../store';
import { isLoginCheck, isNotLoginCheck, isNotSSOLoginCheck, isNotSSOAndStaffLoginCheck } from './loginGuard';

export default [
    //메인
    {
        path: '/kr/index.do',
        component: () => import('../views/kr/main/MainView.vue'),
        children: [{
            path: '/',
            component: () => import('../views/kr/main/MainView.vue')
        }],        
        beforeEnter: (to, from, next) => {
            if (window.location.pathname == '/') {
                window.location.pathname = '/kr/index.do';
            } else {
                return next();
            }
        }
    },
    //통합검색
    {
        path: '/kr/search.do',
        component: () => import ('../views/kr/main/SearchView.vue')
    },
    //로그인
    {
        path: '/kr/login.do',
        component: () => import ('../views/kr/login/LoginView.vue'),
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '로그인');
        }
    },
    //  비밀번호 변경 : 로그인상태
    {
        path: '/kr/help/pw/change.do',
        component: () => import ('../views/kr/help/PwChangeView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },
    //  비밀번호 변경 : 6개월마다 주기적으로 적용
    {
        path: '/kr/help/pw/sixMonthChange.do',
        component: () => import('../views/kr/help/PwSixMonthChangeView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },
    //HELP
    // 아이디 찾기
    {
        path: '/kr/help/id/inquiry.do',
        component: () => import ('../views/kr/help/IdInquiryView.vue'),
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '아이디 찾기');
        }
    },
    {
        path: '/kr/help/id/resolution.do',
        name: 'idResolution',
        component: () => import('../views/kr/help/IdResolutionView.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.loginTypeStore.status == true) {
                alert(`로그인이 되어있습니다.\n아이디 찾기를 하려면 로그아웃을 해주시길 바랍니다.`);
                router.replace('/kr/index.do');
            } else if (store.state.registInfoStore.memFinishEmail == '') {
                alert(`잘못된 접근입니다.`);
                router.replace('/kr/index.do');
            } else {
                return next();
            }
        }
    },    
    //  비밀번호 찾기
    {
        path: '/kr/help/pw/inquiry.do',
        component: () => import ('../views/kr/help/PwInquiryView.vue'),
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '비밀번호 찾기');
        }
    },
    {
        path: '/kr/help/pw/resolution.do',
        name: 'passwordResolution',
        component: () => import('../views/kr/help/PasswordResolutionView.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.loginTypeStore.status == true) {
                alert(`로그인이 되어있습니다.\n비밀번호 찾기를 하려면 로그아웃을 해주시길 바랍니다.`);
                router.replace('/kr/index.do');
            } else if (store.state.registInfoStore.memFinishEmail == '') {
                alert(`잘못된 접근입니다.`);
                router.replace('/kr/index.do');
            } else {
                return next();
            }
        }
    },     
    //회원가입
    //  회원구분
    {
        path: '/kr/account/regist/intro.do',
        component: () => import('../views/kr/account/IntroView.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    //  동의
    {
        path: '/kr/account/regist/step1.do',
        component: () => import('../views/kr/account/Step1View.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    //  인증
    {
        path: '/kr/account/regist/step2.do',
        component: () => import('../views/kr/account/Step2View.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    //  가입FORM
    {
        path: '/kr/account/regist/step3.do',
        component: () => import('../views/kr/account/Step3View.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    //  가입완료
    {
        path: '/kr/account/regist/complete.do',
        component: () => import('../views/kr/account/CompleteView.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '회원가입');
        }
    },
    // 개인정보 재동의
    {
        path: '/kr/account/re-agree.do',
        component: () => import('../views/kr/account/ReAgreeView.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '개인정보 재동의');
        }
    },
    // 휴면계정 안내
    {
        path: '/kr/account/reactivate-dormant-account.do',
        component: () => import('../views/kr/account/ReactivateDormantView.vue'),       
        beforeEnter: (to, from, next) => {
            isLoginCheck(next, '휴면계정 안내');
        }
    },
    // 휴면회원
    {
        path: '/kr/account/sleep.do',
        name: 'sleep',
        component: () => import('../views/kr/account/SleepView.vue'),
    },
    // 휴면회원 수정
    {
        path: '/kr/account/sleepModify.do',
        name: 'sleepModify',
        component: () => import('../views/kr/account/SleepModifyView.vue'),
    },
    //본인인증 완료
    {
        path: '/kr/member/chk_success.do',
        name: 'checkSuccess',
        component: () => import('../views/kr/common/CheckSuccessView.vue')
    },
    
    //교실 타입별 전체 교수진 목록        
    {
        path: '/kr/about/:lgrpCd/class/list.do',
        component: () => import('../views/kr/class/ClassProfessorListView.vue'),
    },
    //교실 정보
    {
        path: '/kr/about/:lgrpCd/class/view.do',
        component: () => import('../views/kr/class/ClassDetailView.vue'),
    },
    //교수님 상세정보
    {
        path: '/kr/professor/detail.do',
        component: () => import('../views/kr/class/ProfessorDetailView.vue'),
    },
    //  회원정보 - 개인정보수정
    {
        path: '/kr/mypage/info/modify.do',
        component: () => import('../views/kr/mypage/infoModifyView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },    
    //  회원정보 - 비밀번호변경
    {
        path: '/kr/mypage/info/password.do',
        component: () => import('../views/kr/mypage/infoPasswordView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },  
    //  회원정보 - 회원탈퇴
    {
        path: '/kr/mypage/info/withdraw.do',
        component: () => import('../views/kr/mypage/infoWithdrawView.vue'),
        beforeEnter: (to, from, next) => {
            isNotLoginCheck(to, from, next);
        }
    },
    // contents 
    {
        path: '/kr/:path1/:path2.do',
        component: () => import('../views/kr/contents/ContentsView.vue'),
        children: [{
            path: '/kr/:path1/:path2/:path3.do',
            component: () => import('../views/kr/contents/ContentsView.vue')
        }]
    },   
    //의학교육본부 - 공지사항
    {
        path: '/kr/headquarters/notice/list.do',
        component: () => import('../views/kr/boards/BoardListView.vue'),
        beforeEnter: (to, from, next) => {
            isNotSSOLoginCheck(to, from, next);
        }
    }, 
    //의학교육본부 - 학년별 자료실
    {
        path: '/kr/headquarters/grade/list.do',
        component: () => import('../views/kr/boards/BoardListView.vue'),
        beforeEnter: (to, from, next) => {
            isNotSSOLoginCheck(to, from, next);
        }
    }, 
    //소식 - 의대 규정집
    {
        path: '/kr/news/regulation/list.do',
        component: () => import('../views/kr/boards/BoardListView.vue'),
        beforeEnter: (to, from, next) => {
            isNotSSOLoginCheck(to, from, next);
        }
    },
    {
        path: '/kr/:path1/:path2/list.do',
        component: () => import('../views/kr/boards/BoardListView.vue'),
        children: [{
            path: '/kr/:path1/:path2/:path3/list.do',
            component: () => import('../views/kr/boards/BoardListView.vue')
        }]
    },
    {
        path: '/kr/:path1/:path2/view.do',
        component: () => import('../views/kr/boards/BoardDetailView.vue'),
        children: [{
            path: '/kr/:path1/:path2/:path3/view.do',
            component: () => import('../views/kr/boards/BoardDetailView.vue')
        }]
    },
    //Diamond Library
    {
        path: '/kr/diamond/library/list.do',
        component: () => import('../views/kr/boards/BoardListView.vue'),
        beforeEnter: (to, from, next) => {
            isNotSSOAndStaffLoginCheck(to, from, next);
        }
    }, 
    //부서템플릿
    //정보전달
    {
        path: '/kr/department/admin/infoTrans/:path1',
        beforeEnter(to, from, next) {
            const newPath = `${to.path}.do`; // .do를 추가한 새로운 경로 생성
            next(newPath); // 새로운 경로로 리다이렉트
        }
    },
    {
        path: '/kr/department/department/infoTrans/:path1.do',
        name: 'dept_infoTrans',
        component: () => import('../views/kr/department/infoTrans/infomationTransferView.vue'),
        alias: [
            '/kr/department/center/infoTrans/:path1.do',
            '/kr/department/cancerTeam/infoTrans/:path1.do',
            '/kr/department/cancerClinic/infoTrans/:path1.do',
            '/kr/department/admin/infoTrans/:path1.do',
        ]
    },
    //퀵메뉴
    {
        path: '/kr/department/admin/quickMenu/:path1',
        beforeEnter(to, from, next) {
            const newPath = `${to.path}.do`; // .do를 추가한 새로운 경로 생성
            next(newPath); // 새로운 경로로 리다이렉트
        }
    },
    {
        path: '/kr/department/department/quickMenu/:path1.do',
        name: 'dept_quickMenu',
        component: () => import('../views/kr/department/quickMenu/quickMenuView.vue'),
        alias: [
            '/kr/department/center/quickMenu/:path1.do',
            '/kr/department/cancerTeam/quickMenu/:path1.do',
            '/kr/department/cancerClinic/quickMenu/:path1.do',
            '/kr/department/admin/quickMenu/:path1.do',
        ]
    },
    //홍보
    {
        path: '/kr/department/admin/promotion/:path1',
        beforeEnter(to, from, next) {
            const newPath = `${to.path}.do`; // .do를 추가한 새로운 경로 생성
            next(newPath); // 새로운 경로로 리다이렉트
        }
    },
    {
        path: '/kr/department/department/promotion/:path1.do',
        name: 'dept_promotion',
        component: () => import('../views/kr/department/promotion/promotionView.vue'),
        alias: [
            '/kr/department/center/promotion/:path1.do',
            '/kr/department/cancerTeam/promotion/:path1.do',
            '/kr/department/cancerClinic/promotion/:path1.do',
            '/kr/department/admin/promotion/:path1.do',
        ]
    },
    // 콘텐츠
    {
        path: '/kr/department/department/:path1.do',
        name: 'dept_content',
        component: () => import('../views/kr/department/content/deptContentsView.vue'),
        alias: [
            '/kr/department/center/:path1.do',
            '/kr/department/cancerTeam/:path1.do',
            '/kr/department/cancerClinic/:path1.do',
            '/kr/department/admin/:path1do',
        ]
    },
    // 게시판
    {
        path: '/kr/department/department/:path1/list.do',
        name: 'dept_board',
        component: () => import('../views/kr/department/board/deptBoardView.vue'),
        alias: [
            '/kr/department/center/:path1/list.do',
            '/kr/department/cancerTeam/:path1/list.do',
            '/kr/department/cancerClinic/:path1/list.do',
            '/kr/department/admin/:path1/list.do',
        ]
    },
    // 게시판 상세 페이지
    {
        path: '/kr/department/department/:path1/view.do',
        name: 'dept_boardDetail',
        component: () => import('../views/kr/department/board/deptBoardDetailView.vue'),
        alias: [
            '/kr/department/center/:path1/view.do',
            '/kr/department/cancerTeam/:path1/view.do',
            '/kr/department/cancerClinic/:path1/view.do',
            '/kr/department/admin/:path1/view.do',
        ]
    },
];
